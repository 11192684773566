import React, { useState } from 'react';

import { Client } from "../utils/Client";

function Navbar({ user }) {
  const [isActive, setIsActive] = useState(false);

  const toggleBurgerMenu = () => {
    setIsActive(!isActive);
  };

  const logout = () => {
    Client.destroySession().then(() => {
      window.location.reload();
    });
  }

  const links = (user === null ? [] : [
    <a className="navbar-item" href="/">home</a>,
    <a className="navbar-item" href="/rooms">rooms</a>
  ]).concat(user !== null && user?.organization_member?.admin ? [
    <a className="navbar-item" href="/rates">rates</a>,
    <a className="navbar-item" href="/properties">properties</a>
  ] : [])

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation"
              style={{fontFamily: "Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif"}}
>
      <div className="navbar-brand">
        { window.location.pathname == '/info' ? <div></div>: <a className="navbar-item" href="/">
          <img src="/images/logo.png" alt="Logo" style={{height:"2.5em"}}/>
        </a> }

        <a
          role="button"
          className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={toggleBurgerMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-start">{links}</div>

        <div className="navbar-end">
          <a className="navbar-item" href="/info">info</a>
          <a className="navbar-item" href="/demo_page">demo</a>
          <div className="navbar-item">
            {user ? (
              <div className="buttons">
                <button className="button is-light" onClick={logout}>Logout</button>
              </div>
            ) : (
              <div className="buttons">
                <a className="button is-light" href="https://admin.stay.annwn.app/login">Login</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
